import { Check } from '@mui/icons-material'
import { Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper, styled } from '@mui/material'
import { useRegisterKit } from '.'
import React from 'react'
import { useLocation } from 'react-router'

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean, completed?: boolean } }>(
  ({ theme }) => ({
    color: '#fff',
    display: 'flex',
    height: 30,
    width: 30,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[600],
    fontSize: 14,
    zIndex: 1,
    fontWeight: 600,
    variants: [
      {
        props: ({ ownerState }) => ownerState.active && !ownerState.completed,
        style: {
          backgroundColor: '#395DB5',
          border: '2px solid #fff',
          outline: '2px solid #395DB5',
          height: 34,
          width: 34
        }
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundColor: theme.palette.success.main
        }
      }
    ]
  })
)

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, icon } = props

  return (
    <CustomStepIconRoot ownerState={ { active, completed } }>
      { completed ? <Check /> : icon }
    </CustomStepIconRoot>
  )
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 4,
    borderRadius: 1
  }
}))

const RegisterKitMobileStepper = () => {
  const { step } = useRegisterKit()
  const location = useLocation()
  const pathname = location.pathname

  let maxWidth = 300
  if (step.total === 4) maxWidth = 250
  if (step.total === 5) maxWidth = 300
  if (step.total === 6) maxWidth = 350

  return (<>
    <Stepper
      alternativeLabel
      activeStep={ step.active }
      sx={ {
        width: '100%',
        maxWidth
      } }
      connector={ <CustomConnector /> }
    >
      { Array.from({ length: step.total }, (_, index) => (
        <Step key={ index } completed={ (step.active > index) || (pathname.includes('success')) }>
          <StepLabel StepIconComponent={ CustomStepIcon } />
        </Step>
      )) }
    </Stepper>
  </>)
}

export default RegisterKitMobileStepper
