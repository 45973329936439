import React from 'react'
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import Questionnaire, { useQuestionnaire } from 'components/Questionnaire'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { RegisterKitDataI, RegisterKitNavigation, sitePaymentMethod, useRegisterKit } from '.'
import { api } from 'Core'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'
import { Prompt } from 'components/Prompt'
import useStateCallback from 'hooks/useStateCallback'
import { useTranslation } from 'react-i18next'
import { Kit } from '../../types/db/Kit'
import { QuestionI } from '../../types/db/KitTypeConfigI'
import { analyticEventTracker } from 'utils/analytics'

function checkInsuranceEligibility (answers: Kit['answers']) {
  const suspectExposure = answers[12] === 'Yes'
  const symptomsOfConcern = answers[14] === 'Yes'

  if (suspectExposure || symptomsOfConcern) {
    return { isEligible: true, why: {} }
  }

  return { isEligible: true, why: {} }
}

function ScreeningForm () {
  const navigate = useNavigate()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const [showPromptOnExit, setShowPromptOnExit] = useStateCallback(true)

  const { data, updateData, saveKitAction } = useRegisterKit()

  let questionsToRender: QuestionI[] = getQuestionsToRender(data)

  if (
    data.patientInfoForm.patient?.sex_assigned_at_birth && !['Prefer not to answer', 'Female'].includes(
      data.patientInfoForm.patient.sex_assigned_at_birth
    )
  ) {
    questionsToRender = questionsToRender.filter(
      (question) => !question.name.toLowerCase().includes('pregnant')
    )
  }

  const handleSubmit = async (formData: Kit['answers']) => {
    setShowPromptOnExit(false)
    const kit = await api.kit.update(data?.kit?.id, { answers: formData, pageInfo: 'ScreeningQuestionaire' })

    const { isEligible, why } = checkInsuranceEligibility(formData)

    updateData((state) => ({
      ...state,
      kit: { ...kit, isProgramKit: state?.kit?.isProgramKit },
      screeningForm: { answers: formData },
      insurance: {
        isEligible,
        why
      }
    }))
    analyticEventTracker('(E) Next Button Clicked on Screening Question Page', {
      category: 'Register kit'
    })
    saveKitAction('ScreeningQuestionaire')

    if (
      data.payment === 'prepaid' ||
      sitePaymentMethod(data?.site || undefined, data?.kit?.kitTypeConfig) === 'prepaid'
    ) {
      navigate('../sample-collection')
      return
    }

    navigate('../payment-method')
  }

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showPromptOnExit)

  const { t } = useTranslation()

  return (
    <Stack sx={ { gap: 3 } }>
      <Prompt
        open={ showPrompt && showPromptOnExit }
        onClose={ cancelNavigation }
        confirmNavigation={ confirmNavigation }
      />
      <Typography variant="h5" component="h2">
        { t('registerKit.screening.title') }
      </Typography>
      <Stack sx={ { gap: 2 } }>
        <Typography variant="subtitle1" component="h3">
          { t('registerKit.screening.subtitle') }
        </Typography>
        <Questionnaire
          answers={ data.screeningForm.answers }
          questions={ questionsToRender }
          onSubmit={ handleSubmit }
        >
          <RegisterKitNavigation
            back={
              <Button
                fullWidth={ mobile }
                onClick={ () => {
                  analyticEventTracker('(E) Back Button Clicked on Screening Question Page', {
                    category: 'Register kit'
                  })
                  setShowPromptOnExit(false, () => navigate('../patient-info'))
                } }
                sx={ {
                  textDecoration: mobile ? 'underline' : 'none',
                  '&:hover': { textDecoration: mobile ? 'underline' : 'none' },
                  '&:active': { textDecoration: mobile ? 'underline' : 'none' }
                } }
              >
                { t('multiFormNavigation.back') }
              </Button>
            }
            next={ <SubmitButton fullWidth={ mobile }>{ t('multiFormNavigation.next') }</SubmitButton> }
          />
        </Questionnaire>
      </Stack>
    </Stack>
  )
}

function SubmitButton ({ children, ...props }: SubmitButtonProps) {
  const { formik } = useQuestionnaire()

  return (
    <LoadingButton
      loading={ formik.isSubmitting }
      type="submit"
      variant="contained"
      { ...props }
    >
      { children }
    </LoadingButton>
  )
}

const getQuestionsToRender = (data: RegisterKitDataI): QuestionI[] => {
  if (!data || !data.site || !data.kit || !data.kit.kitTypeConfig) {
    return []
  }

  const overrideQuestions = data?.site?.override_screening_questions?.[data?.kit?.kitTypeConfig?.id]
  let questions = overrideQuestions || data?.kit?.kitTypeConfig?.screeningQuestions

  if (!questions) return []
  if (typeof questions === 'string') {
    try {
      questions = JSON.parse(questions)
    } catch (e) {
      console.error('Error parsing override questions', e)
      questions = []
    }
  }

  if (!Array.isArray(questions)) {
    console.error('Error parsing override questions', questions)
    questions = []
  }

  return questions.filter(question => !!question.isActive)
}

interface SubmitButtonProps {
  children: React.ReactNode
  fullWidth?: boolean
}

export default ScreeningForm
