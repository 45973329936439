import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import { Trans, useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

const AvoidShippingDelaysDialog = ({ formik, open, handleClose }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Dialog
      open={open}
      PaperProps={ {
        sx: {
          padding: 'clamp(.5rem, 2vw, 1.5rem)',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }
      } }
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {t('registerKit.avoidDelay.heading')}
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Stack direction='column' gap={2} justifyContent='center' alignItems='center'>
          <Typography variant='body1'>
            <Trans i18nKey='registerKit.avoidDelay.description'>
              Please register kits and collect samples <strong>Sunday through Thursday</strong> and <strong>outside of public holidays</strong> to avoid shipping delays.
            </Trans>
          </Typography>
          <Stack direction='row' gap={{ xs: 1, md: 2 }} justifyContent='center' flexWrap='wrap'>
            <Avatar sx={{ bgcolor: 'success.main', fontSize: '12px' }}> {t('registerKit.avoidDelay.sun')}</Avatar>
            <Avatar sx={{ bgcolor: 'success.main', fontSize: '12px' }}> {t('registerKit.avoidDelay.mon')}</Avatar>
            <Avatar sx={{ bgcolor: 'success.main', fontSize: '12px' }}> {t('registerKit.avoidDelay.tue')}</Avatar>
            <Avatar sx={{ bgcolor: 'success.main', fontSize: '12px' }}> {t('registerKit.avoidDelay.wed')}</Avatar>
            <Avatar sx={{ bgcolor: 'success.main', fontSize: '12px' }}> {t('registerKit.avoidDelay.thu')}</Avatar>
            <Avatar sx={{ bgcolor: 'disabled.main', fontSize: '12px' }}>{t('registerKit.avoidDelay.fri')}</Avatar>
            <Avatar sx={{ bgcolor: 'disabled.main', fontSize: '12px' }}>{t('registerKit.avoidDelay.sat')}</Avatar>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={theme => ({
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            paddingX: `${theme.spacing(2)}`,
            gap: 2
          }
        })}
      >
        <Button
          variant='outlined'
          onClick={() => {
            handleClose()
            formik.handleSubmit()
          }}
          sx={{ flex: 1, width: '100%' }}
        >
          {t('registerKit.avoidDelay.continueRegister')}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            handleClose()
            navigate('/')
          }}
          sx={{ flex: 1, width: '100%' }}
        >
          {t('registerKit.avoidDelay.goback')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface Props {
  formik: ReturnType<typeof useFormik<{
    kitcode: string;
    sitecode: string | null;
    isKitFulfilled: boolean;
}>>
  open: boolean
  handleClose: () => void
}

export default AvoidShippingDelaysDialog
