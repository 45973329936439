import _ from 'lodash'
import { api } from 'Core'
import { getDomainName, getSubdomain, isShkDomain } from '../utilFuntions'
import { PageNotFound } from 'components'
import { LandingPageSectionI, SiteI, WhiteLabelConfig, WhiteLabelTheme } from 'types/db/SiteI'
import { ThemeProvider, createTheme } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Helmet } from 'react-helmet'
import invariant from 'tiny-invariant'
import Loader from 'components/Loader'
import React from 'react'
import theme, { createCustomTheme, themeColors, themeTypographies } from 'theme'

export function useWhiteLabelConfig () {
  const domain = getDomainName()

  const queryClient = useQueryClient()
  const whiteLabelConfigData = queryClient.getQueryData<WhiteLabelConfig>([
    'white-label-config',
    domain
  ])

  invariant(
    whiteLabelConfigData,
    'useWhiteLabelConfig must be in scope with <WhiteLabelProvider />'
  )
  invariant(_.every([whiteLabelConfigData.logoUrl, whiteLabelConfigData.logoDimensions], Boolean), 'Invalid white label config')
  return {
    ...whiteLabelConfigData,
    ftpLandingPageConfig: whiteLabelConfigData?.ftpLandingPageConfig as LandingPageSectionI[]
  }
}

export function WhiteLabelConfigProvider ({
  children
}: {
  children: React.ReactElement | React.ReactElement[]
}) {
  const domain = getDomainName()
  const subdomain = getSubdomain()

  const shkDefaultSettingsApiCall = (): Promise<SiteI['white_label_config']> => {
    return new Promise((resolve) => {
      resolve({
        sourceEmail: {
          name: 'Simple HealthKit Lab',
          email: 'no-reply@simplehealthkit.com'
        },
        supportEmail: 'help@simplehealthkit.com',
        domain: 'simplehealthkit.com',
        isActive: false,
        languageSupport: true,
        variables: {
          appUrl: process.env.REACT_APP_URL,
          appDomain: process.env.REACT_APP_DOMAIN,
          appMainUrl: process.env.REACT_APP_MAIN_URL,
          auth0ActionNamespace: process.env.REACT_APP_AUTH0_ACTION_NAMESPACE
        },
        theme: {
          palette: {
            ...themeColors.palette
          },
          typography: {
            ...themeTypographies.typography
          },
          customComponents: {
            header: {
              bgColor: '#FFF',
              textColor: '#333'
            },
            btn: {
              borderRadius: 32
            }
          }
        },
        logoUrl: '/assets/reskinning/shk-logo.svg',
        logoDimensions: {
          width: 120,
          height: 120
        },
        privacy_policy: 'https://www.simplehealthkit.com/privacy-policy/',
        terms_conditions: 'https://www.simplehealthkit.com/terms/',
        siteName: 'Simple HealthKit',
        weekend_disclaimer: {
          enabled: true
        }
      })
    })
  }

  const {
    isLoading,
    isError,
    data: whiteLabelConfig
  } = useQuery(['white-label-config', domain], () => {
    if (isShkDomain()) {
      return shkDefaultSettingsApiCall()
    }
    return api.site.getSiteBrand(domain, true)
  })

  if (isLoading) return <Loader />
  if (!isLoading && isError) return <PageNotFound />

  const themeToUse = overrideDefaultTheme(whiteLabelConfig || {} as WhiteLabelConfig, subdomain)

  return (
    <ThemeProvider theme={ themeToUse }>
      <Helmet >
        {
          whiteLabelConfig?.fontUrl
            ? (<link href={ whiteLabelConfig?.fontUrl } rel="stylesheet" />)
            : null
        }

        <link rel="manifest" href={ isShkDomain() ? '/manifest.json' : '/manifest-generic.json' } />

        <link rel="icon" type="image/png" href={ isShkDomain() ? '/favicon/shk/favicon-32x32.png' : whiteLabelConfig?.faviconUrl || '/favicon/generic/generic.png' } />
        <title>{isShkDomain() ? 'Simple HealthKit' : whiteLabelConfig?.siteName}</title>
      </Helmet>
      { children }
    </ThemeProvider>
  )
}

function overrideDefaultTheme (whiteLabelConfig: WhiteLabelConfig, subdomain: string) {
  if (subdomain !== 'app') return theme

  const whiteLabelTheme = whiteLabelConfig?.theme || {} as WhiteLabelTheme
  const typography = whiteLabelConfig?.fontUrl ? whiteLabelTheme?.typography : theme?.typography

  const colorsAndTypographies = createTheme({
    palette: {
      ...whiteLabelTheme?.palette as any
    },
    typography
  })

  const newTheme = createCustomTheme(colorsAndTypographies, whiteLabelTheme.customComponents)

  return newTheme
}
