import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  TextField,
  Typography,
  Stack,
  Divider,
  Button,
  IconButton,
  Link,
  Alert
} from '@mui/material'
import { analyticEventTracker } from 'utils/analytics'
import { api } from 'Core'
import { checkAffiliate } from 'utils/affiliateUtilFunctions'
import { Container, Paper, TabWrapper } from 'components'
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { OpenInNew } from '@mui/icons-material'
import { showSnackBar } from 'state/actions'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import useAuth0 from 'hooks/useAuth0'
import useFormik from 'hooks/useFormik'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ForgotPasswordDialog from 'components/ForgotPasswordDialog'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from 'i18n'
import { getSubdomain, isShkDomain } from 'utils/utilFuntions'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import { isEmpty } from 'lodash'
import moment from 'moment'
import cookieUtils from 'Core/utils/cookieUtils'
import jwtUtil from 'Core/utils/jwtUtil'
import useFingerprint from 'utils/useFingerprint'

const Login = ({ displayedComponent = '', setDisplayedComponent }: Props) => {
  const { login } = useAuth0()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const whiteLabelConfig = useWhiteLabelConfig()
  const [accountState, setAccountState] = useState('init')
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()
  const hideSignUp = pathname.includes('view-result')
  const isShkEnabled = isShkDomain()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const redirectParams = searchParams.get('redirectTo')
  const fingerprint = useFingerprint()

  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(showPassword => !showPassword)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      try {
        // Cast values to trigger the transformations in the schema (trim, etc)
        const castValues = validationSchema.cast(values)

        const subdomain = getSubdomain()
        const isSsoUser = jwtUtil.checkSsoUser();

        const response = await api.auth.checkAccountExists({
          email: castValues.email /* token */
        })

        if (!response.success) {
          setAccountState('notFound')
        } else {
          const isSnoozed = localStorage.getItem(`mfa_setup_snooz_${castValues.email}`)
          const today = moment()

          if (!response?.email_verified) {
            const data = await api.portalAuth.send2FaOtp({
              email: castValues.email,
              password: castValues.password
            })
            if (data?.success) {
              return navigate(`/verify-otp/${data?.token}${subdomain === 'app' ? redirectParams ? `?redirectTo=${redirectParams}` : '?redirectTo=/mfa-config/setup' : ''}`, {
                state: {
                  email: castValues.email,
                  password: castValues.password,
                  otpSend: true,
                  emailVerified: response?.email_verified,
                  phone: response?.phone,
                  mfa_method: response.mfa_method
                }
              })
            } else {
              throw Error('Something went wrong!')
            }
          } else if (response.mfa_enabled && !isSsoUser) {
            const resp = await api.portalAuth.getRememberDevice({ fingerprint: `${fingerprint}_${castValues.email}` || '' });

            if (!resp.success) {
              const data = await api.portalAuth.send2FaOtp({
                email: castValues.email,
                password: castValues.password
              })
              if (data?.success) {
                return navigate(`/verify-otp/${data?.token}${redirectParams ? `?redirectTo=${redirectParams}` : ''}`, {
                  state: {
                    email: castValues.email,
                    password: castValues.password,
                    otpSend: !!response.mfa_method,
                    emailVerified: response?.email_verified,
                    phone: response?.phone,
                    mfa_method: response.mfa_method
                  }
                })
              } else {
                throw Error('Something went wrong!')
              }
            } else {
              await login(castValues.email as string, castValues.password as string, '', getCurrentLanguage(i18n), {
                token: `${fingerprint}_${castValues.email}` || '',
                fingerprint: fingerprint || ''
              });
              // Register the successful login in analytics
              analyticEventTracker(`Login to ${subdomain}`, { category: 'Authentication' })
            }
          } else {
            if ((!isSnoozed || today.isAfter(moment(isSnoozed), 'day')) && subdomain === 'app' && !redirectParams && !isSsoUser) {
              setSearchParams({ ...Object.fromEntries(searchParams), redirectTo: '/mfa-config/setup' })
            }
            await login(castValues.email as string, castValues.password as string, '', getCurrentLanguage(i18n))
            // Register the successful login in analytics
            analyticEventTracker(`Login to ${subdomain}`, { category: 'Authentication' })
          }
        }
      } catch (error: any) {
        let errorText = t('login.alert.error')
        if (error?.responseJSON?.error === 'Please try to login from SSO !') {
          errorText = t('login.alert.ssoError')
          analyticEventTracker('SSO user trying to login with username password', { category: 'Authentication' })
          return setAccountState('ssoError')
        }
        if (error?.description === 'Wrong email or password.') {
          // Register the wrong login in analytics
          analyticEventTracker('Wrong email or password', { category: 'Authentication' })
          return setAccountState('notFound')
        } else if (error?.responseJSON?.error === 'Invalid Username or Password') {
          // Register the wrong login in analytics
          analyticEventTracker('Wrong email or password', { category: 'Authentication' })
          return setAccountState('notFound')
        } else {
          dispatch(
            showSnackBar({
              show: true,
              message: errorText,
              severity: 'error'
            })
          )
        }
      }
    }
  })

  useEffect(() => {
    const loginUser = searchParams.get('loginUser')
    if (loginUser) {
      formik.setFieldValue('email', loginUser);
      searchParams.delete('loginUser')
      setSearchParams(new URLSearchParams(searchParams))
    }
  }, [])

  return (
    <Container>
      <Stack sx={ { gap: 3 } } component='form' onSubmit={ formik.handleSubmit }>
        <Typography variant='h4' component='h1' sx={ { textAlign: 'center' } }>
          {
            checkAffiliate() ? t('login.heading.portal') : isShkEnabled ? t('homepage.title') : t('member.magiclink.welcome')
          }
        </Typography>
        <Paper sx={ { width: '100%', maxWidth: 550, m: 'auto' } }>
          <Stack sx={ { gap: 3 } }>
            <Typography variant='h5' component='h2'>{ t('login.title') }</Typography>
            <Stack sx={ { gap: 2 } }>
              <TextField
                name='email'
                value={formik.values?.email ? formik.values?.email : null}
                label={ `${t('login.formfields.emailAddress')} *` }
                onChange={ formik.handleChange }
                helperText={ formik.touched.email && Boolean(formik.errors.email) && t(formik.errors.email) }
                error={ formik.touched.email && Boolean(formik.errors.email) }
              />
              <TextField
                type={ showPassword ? 'text' : 'password' }
                name='password'
                label={ `${t('login.formfields.password')} *` }
                onChange={ formik.handleChange }
                helperText={
                  formik.touched.password &&
                  Boolean(formik.errors.password) &&
                  t(formik.errors.password)
                }
                InputProps={ {
                  endAdornment: (
                    <IconButton onClick={ toggleShowPassword } aria-label='visibility'>
                      { showPassword
                        ? (<VisibilityOffIcon />)
                        : (<VisibilityIcon />)
                      }
                    </IconButton>
                  )
                } }
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
              />

              { accountState === 'notFound' || accountState === 'ssoError'
                ? (<Alert severity='error'
                    sx={{ alignItems: 'center' }}
                   >
                    <Typography
                      variant='body2'
                      sx={ {
                        color: 'error.main',
                        fontWeight: 500
                      } }
                    >
                      { t('login.error.incorrectCombination') }
                    </Typography>
                    {accountState === 'ssoError' ? (
                      <Typography
                        variant='body2'
                        sx={ {
                          color: 'error.main',
                          fontWeight: 500
                        } }
                      >
                        { t('login.alert.ssoError') }
                      </Typography>
                    ) : null}
                </Alert>)
                : null }

              <TabWrapper sx={ { width: 'fit-content' } } onClick={ () => setOpen(true) }>
                <Typography
                  tabIndex={ 0 }
                  variant='body2'
                  component='p'
                  role='button'
                  sx={ {
                    fontWeight: 500,
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  } }
                >
                  { t('login.forgotPassword.title') }
                </Typography>
              </TabWrapper>
            </Stack>
            <Stack
              sx={ { gap: 2, my: 1 } }
              divider={ hideSignUp ? null : <Divider sx={ { fontSize: 13 } }>{ t('login.or') }</Divider> }
            >
              <Stack gap={ 2 }>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={ formik.isSubmitting }
                  fullWidth
                >
                  { t('login.title') }
                </LoadingButton>

                {
                  isShkDomain()
                    ? <Link
                      component={ RouterLink }
                      to={ '../login-sso' }
                      replace
                      sx={ { display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' } }
                    >
                      { t('homepage.ssoRedirectText') }
                    </Link>
                    : null
                }

              </Stack>
              {
                hideSignUp
                  ? null
                  : (displayedComponent && setDisplayedComponent
                      ? (<Button
                      type='button'
                      fullWidth
                      variant='outlined'
                      onClick={ () => setDisplayedComponent('signup') }
                    >
                      { t('login.button.createAccount') }
                    </Button>)
                      : (<Button
                      type='button'
                      fullWidth
                      variant='outlined'
                      component={ RouterLink }
                      to={`/signup${redirectParams ? `?redirectTo=${redirectParams}` : ''}`}
                    >
                      { t('login.button.createAccount') }
                    </Button>))
              }
            </Stack>

            {
              pathname !== '/'
                ? (<Typography variant='body2' sx={ { textAlign: 'center' } }>
                  { whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.terms_conditions)
                    ? ''
                    : (<>
                      <Link
                        href={ whiteLabelConfig.isActive ? whiteLabelConfig?.terms_conditions || '' : 'https://www.simplehealthkit.com/terms' }
                        target='_blank'
                        rel='noreferrer'
                      >
                        { t('login.termsAndCondition') } <OpenInNew fontSize='inherit' />
                      </Link>
                      { ' | ' }
                    </>)
                  }
                  { whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.privacy_policy)
                    ? ''
                    : (<>
                      <Link
                        href={ whiteLabelConfig.isActive ? whiteLabelConfig?.privacy_policy || '' : 'https://www.simplehealthkit.com/privacy' }
                        target='_blank'
                        rel='noreferrer'
                      >
                        { t('footer.privacyPolicy') } <OpenInNew fontSize='inherit' />
                      </Link>
                    </>)
                  }
                </Typography>)
                : null
            }
          </Stack>
        </Paper>
      </Stack>
      <ForgotPasswordDialog open={ open } onClose={ () => setOpen(false) } />
    </Container>
  )
}

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('login.error.validEmail')
    .required('login.error.email'),
  password: yup
    .string()
    .min(8, 'login.error.incorrectpassword')
    .required('login.error.password')
})

interface Props {
  displayedComponent?: string
  setDisplayedComponent?: Dispatch<SetStateAction<string>>
}

export default Login
