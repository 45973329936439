import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  TextField,
  Typography,
  Stack,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  IconButton,
  CircularProgress
} from '@mui/material'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import useAuth0 from 'hooks/useAuth0'
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { Container, Paper } from 'components'
import { showSnackBar } from 'state/actions'
import { useDispatch } from 'react-redux'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import PasswordPolicy from 'components/PasswordPolicy'
import { api } from 'Core'
import { analyticEventTracker } from 'utils/analytics'
import cookieUtils from 'Core/utils/cookieUtils'
import moment from 'moment'
import { checkAffiliate, getAffiliate } from 'utils/affiliateUtilFunctions'
import { isPasswordContainsUserInfo, isShkDomain } from 'utils/utilFuntions'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from 'i18n'
import { useWhiteLabelConfig } from 'utils/white-label/WhiteLabelConfig'
import { isEmpty } from 'lodash'
import TermsPolicy from 'components/TestResult/components/TermsPolicy'
import HowToRegisterYourKitInstructions from '../../components/HowToRegisterYourKitInstructions'

export const personalInfoValidator = (value: string = '', context: PersonalInfoValidatorContextI) => {
  const formData = { ...context.parent, password: value }
  // If the user has not filled the form, the value will be undefined, so we need to set it to empty string
  Object.keys(formData).forEach(key => {
    if (formData[key as keyof PersonalInfoValidatorContextI['parent']] === undefined) {
      formData[key as keyof PersonalInfoValidatorContextI['parent']] = ''
    }
  })
  return !isPasswordContainsUserInfo(formData)
}

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'signup.error.firstNameLength')
    .required('signup.error.firstName'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'signup.error.lastNameLength')
    .required('signup.error.lastName'),
  email: yup
    .string()
    .trim()
    .email('signup.error.validEmail')
    .required('signup.error.email'),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref('email'), null], 'signup.error.emailMatch').required('signup.error.confirmEmail'),
  password: yup
    .string()
    .trim()
    .strict(true)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'signup.error.strongPassword'
    )
    .required('signup.error.password')
    .test('checkPasswordAgainstUserInfo', 'signup.error.personalInfo', personalInfoValidator),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'signup.error.passwordMatch').required('signup.error.confirmPassword'),
  // confirmPassword: yup.string().required('Confirm password is required'),
  terms: yup.boolean().oneOf([true], 'signup.error.acceptTerms')
  // might need confirm password
})

const defaultDisabledFields = {
  firstName: false,
  lastName: false,
  email: false
}

const Signup = ({ displayedComponent = '', setDisplayedComponent, description = <></>, defaultValues = {}, disabledFields = defaultDisabledFields, hideGreeting = false }: Props) => {
  const { signup } = useAuth0()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const whiteLabelConfig = useWhiteLabelConfig()
  const isShkEnabled = isShkDomain()
  const navigate = useNavigate()

  const [showPolicy, setShowPolicy] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [accountState, setAccountState] = useState('init')
  const toggleShowPassword = () =>
    setShowPassword(showPassword => !showPassword)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(showPassword => !showPassword)
  const [searchParams] = useSearchParams()
  const redirectParams = searchParams.get('redirectTo')

  // Display instructions to register the kit when the user is redirected from the QR code
  const displayInstructions = (Boolean(redirectParams) && redirectParams?.includes('/r/')) || false

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      terms: false,
      key: '',
      ...defaultValues
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      try {
        // Cast values to trigger the transformations in the schema (trim, etc)
        const castValues = validationSchema.cast(values)

        const auth0 = await signup(castValues.email as string, castValues.password as string, {
          firstName: castValues.firstName,
          lastName: castValues.lastName,
          toBeLinked: 'true',
          userType: getAffiliate(),
          tncDate: `${moment().unix()}`,
          language: getCurrentLanguage(i18n)
        })
        if (auth0) {
          try {
            if (values.key) {
              await api.healthPlan.assignAccount(auth0.user?.sub, values.key)
            }
          } catch (error) {
            console.error(error)
          }
          const subdomain = await api.auth.getSubdomain(castValues.email)

          // await login(values.email, values.password, subdomain, getCurrentLanguage(i18n))
          // Register the successful signup in analytics
          analyticEventTracker('Successful signup', { category: 'Authentication' })
          // Set a cookie to check if the user register pacient data on the same session as the signup
          cookieUtils.setCookie('newUser', 'true', moment().add(1, 'day').utc())

          const data = await api.portalAuth.send2FaOtp({
            email: castValues.email,
            password: castValues.password
          })
          if (data?.success) {
            return navigate(`/verify-otp/${data?.token}${subdomain === 'app' ? redirectParams ? `?redirectTo=${redirectParams}` : '?redirectTo=/mfa-config/setup' : ''}`, {
              state: {
                email: castValues.email,
                password: castValues.password,
                otpSend: true,
                emailVerified: false,
                phone: ''
              }
            })
          } else {
            throw Error('Something went wrong!')
          }
        }
      } catch (error: any) {
        if (error?.responseJSON?.error === 'Please try to login from SSO !') {
          analyticEventTracker('SSO user trying to Signup with username password', { category: 'Authentication' })
          setTimeout(() => {
            navigate('/login-sso');
          },3000)
          return setAccountState('ssoError')
        }
        console.log(error)
        dispatch(
          showSnackBar({
            show: true,
            message: t('signup.alert.error'),
            severity: 'error'
          })
        )
      }
    }
  })

  if (whiteLabelConfig?.isActive && isEmpty(whiteLabelConfig?.terms_conditions) && isEmpty(whiteLabelConfig?.privacy_policy) && !formik.values.terms) {
    formik.setFieldValue('terms', true)
    setShowPolicy(false)
  }

  return (
    <Container>
      <Stack
        sx={ { gap: 3, width: '100%', maxWidth: 550, m: 'auto' } }
        component='form'
        onSubmit={ formik.handleSubmit }
        autoComplete='off'
      >
        { !hideGreeting
          ? <Typography variant='h4' component='h1' sx={ { textAlign: 'center' } }>
            {
              checkAffiliate() ? t('signup.heading.portal') : isShkEnabled ? t('homepage.title') : t('member.magiclink.welcome')
            }
          </Typography>
          : null }

        <HowToRegisterYourKitInstructions isComponentVisible={ displayInstructions } />

        <Paper>
          <Stack sx={ { gap: 3 } }>
            <Typography variant='h5' component='h2'>
              { t('signup.form.title') }
            </Typography>
            { description }
            <Stack
              gap={ 2 }
              component='form'
              onSubmit={ formik.handleSubmit }
              autoComplete='off'
            >
              <TextField
                id='first-name'
                name='firstName'
                label={ `${t('signup.formfields.firstName')} *` }
                value={ formik.values.firstName || '' }
                onChange={ formik.handleChange }
                helperText={
                  formik.touched.firstName &&
                  Boolean(formik.errors.firstName) &&
                  t(formik.errors.firstName)
                }
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                disabled={ disabledFields.firstName }
              />

              <TextField
                id='last-name'
                name='lastName'
                label={ `${t('signup.formfields.lastName')} *` }
                value={ formik.values.lastName || '' }
                onChange={ formik.handleChange }
                helperText={
                  formik.touched.lastName &&
                  Boolean(formik.errors.lastName) &&
                  t(formik.errors.lastName)
                }
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                disabled={ disabledFields.lastName }
              />

              <TextField
                id='email'
                name='email'
                required
                label={ t('signup.formfields.emailAddress') }
                value={ formik.values.email || '' }
                onChange={ (event) => {
                  event.target.value = event.target.value.trim()
                  formik.handleChange(event)
                } }
                helperText={
                  formik.touched.email &&
                  Boolean(formik.errors.email) &&
                  t(formik.errors.email)
                }
                error={ formik.touched.email && Boolean(formik.errors.email) }
                disabled={ disabledFields.email }
              />
              {accountState === 'ssoError' ? (
                <>
                  <Typography
                    variant='body2'
                  >
                    { t('login.alert.ssoSignUpErrorText1') }
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ color: '#00000099', display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <CircularProgress size={16} /> { t('login.alert.ssoSignUpErrorText2') }
                  </Typography>
                </>
              ) : null}
              <TextField
                required
                id='confirmEmail'
                name='confirmEmail'
                label={ t('signup.formfields.confirmEmailAddress') }
                value={ formik.values.confirmEmail || '' }
                onPaste={ (e) => e.preventDefault() }
                onChange={ (event) => {
                  event.target.value = event.target.value.trim()
                  formik.handleChange(event)
                } }
                helperText={
                  formik.touched.confirmEmail &&
                  Boolean(formik.errors.confirmEmail) &&
                  t(formik.errors.confirmEmail)
                }
                error={ formik.touched.confirmEmail && Boolean(formik.errors.confirmEmail) }
              />

              <TextField
                id='password'
                name='password'
                label={ `${t('signup.formfields.password')} *` }
                type={ showPassword ? 'text' : 'password' }
                value={ formik.values.password || '' }
                onChange={ formik.handleChange }
                onPaste={ (e) => e.preventDefault() }
                InputProps={ {
                  endAdornment: (
                    <IconButton onClick={ toggleShowPassword } aria-label='visibility'>
                      { showPassword
                        ? (<VisibilityOffIcon />)
                        : (<VisibilityIcon />)
                      }
                    </IconButton>
                  )
                } }
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={
                  formik.touched.password &&
                  Boolean(formik.errors.password) &&
                  t(formik.errors.password)
                }
              />

              <TextField
                id='confirm-password'
                name='confirmPassword'
                label={ `${t('signup.formfields.confirmPassword')} *` }
                type={ showConfirmPassword ? 'text' : 'password' }
                value={ formik.values.confirmPassword || '' }
                onChange={ formik.handleChange }
                onPaste={ (e) => e.preventDefault() }
                InputProps={ {
                  endAdornment: (
                    <IconButton onClick={ toggleShowConfirmPassword } aria-label='visibility'>
                      { showConfirmPassword
                        ? (
                          <VisibilityOffIcon />
                          )
                        : (
                          <VisibilityIcon />
                          ) }
                    </IconButton>
                  )
                } }
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  t(formik.errors.confirmPassword)
                }
              />

              <PasswordPolicy context={ formik.values } type='signup' />

              { showPolicy
                ? <Stack>
                  <FormControl
                    error={ !!formik.errors.terms }
                    sx={ { textAlign: 'start' } }
                  >
                    <FormControlLabel
                      sx={ { borderRadius: '0 0 5px 5px' } }
                      control={
                        <Checkbox
                          name='terms'
                          checked={ formik.values.terms || false }
                          onChange={ formik.handleChange }
                        />
                      }
                      label={
                        <Typography>
                          <TermsPolicy />
                        </Typography>
                      }
                    />
                    <FormHelperText>{ t(formik.errors.terms) }</FormHelperText>
                  </FormControl>
                </Stack>
                : ''
              }

            </Stack>

            <LoadingButton
              type='submit'
              variant='contained'
              loading={ formik.isSubmitting }
              fullWidth
            >
              { t('signup.button.createAccount') }
            </LoadingButton>

            <Typography variant='body2' sx={ { display: 'flex', justifyContent: 'center' } }>
              { t('signup.alreadyHaveAccount') } {
                displayedComponent
                  ? (<Typography
                    variant='body2'
                    sx={ { fontWeight: 'bold', mx: 1, cursor: 'pointer' } }
                    onClick={ () => {
                      if (setDisplayedComponent) {
                        setDisplayedComponent('login')
                      }
                    } }
                  >
                    { t('signup.link.login') }
                  </Typography>)
                  : (<Typography
                    variant='body2'
                    sx={ { fontWeight: 'bold', mx: 1 } }
                    component={ RouterLink }
                    to={`/${redirectParams ? `?redirectTo=${redirectParams}` : ''}`}
                  >
                    { t('signup.link.login') }
                  </Typography>)
              } { t('signup.instead') }
            </Typography>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  )
}

interface Props {
  displayedComponent?: string
  setDisplayedComponent?: Dispatch<SetStateAction<string>>
  description?: React.ReactElement
  defaultValues?: any
  disabledFields?: any
  hideGreeting?: boolean
}

interface PersonalInfoValidatorContextI {
  parent: {
    password: string;
    firstName: string;
    lastName: string;
    email: string;
  }
}

export default Signup
