import { PatientFromLimsI } from '../types/PatientFromLimsI'
import { Kit } from '../types/db/Kit'
import { KitTypeConfigI } from '../types/db/KitTypeConfigI'
import { PatientI } from '../types/db/PatientI'
import { SiteI } from '../types/db/SiteI'
import { getAge } from './utilFuntions'
import _ from 'lodash'

export const KIT_TYPE_IDENTIFIERS: Record<string, string> = Object.freeze({
  COVID19: 'COVID19',
  COVID19A: 'COVID19A',
  STI: 'STI',
  EXPANDED_STI: 'EXPANDED_STI',
  RESP_WELLNESS: 'RESP_WELLNESS',
  SB: 'SB',
  DBTST: 'DBTST',
  LPTST: 'LPTST',
  KIDNEY_HEALTH_TEST: 'KH',
  KEDHP: 'KEDHP',
  BHCG: 'BHCG',
  EXPANDED_MS_STI: 'EXPANDED_MS_STI'
})

const kitTypeIdentifiersMap: Record<string, string> = {
  COVID19: 'COVID19',
  COVID19A: 'COVID19A',
  STI: 'STI',
  EXPANDED_STI: 'EXPANDED_STI',
  RESP_WELLNESS: 'RESP_WELLNESS',
  SB: 'SB',
  DBTST: 'DBTST',
  LPTST: 'LPTST',
  KH: 'KH',
  BHCG: 'BHCG',
  EXPANDED_MS_STI: 'EXPANDED_MS_STI',
  KEDHP: 'KEDHP'
}

export function getKitTypeIdentifier (kitTypeConfig: KitTypeConfigI) {
  return kitTypeIdentifiersMap[kitTypeConfig.identifier]
}

export function getKitCodePrefix (kitCode: string) {
  const prefix = kitCode.split('-')[0]
  return prefix
}

export const notAllowedKitsForBelow18 = ['SS', 'SA']

export function validateKitAgainstPatientAge (kit: Kit | undefined, patient: PatientI | PatientFromLimsI, site: SiteI | undefined) {
  if (!kit || !site) return false

  const patientAge = getAge(patient && 'dob' in patient ? patient.dob : patient.date_of_birth || '')
  const kitCodePrefix = getKitCodePrefix(kit.code)
  const minAgeLimit = site?.age_config?.[kit?.kitTypeConfig?.prefix || kitCodePrefix]?.patientMinAge || 0
  const supervisedAgeLimit = site?.age_config?.[kit?.kitTypeConfig?.prefix || kitCodePrefix]?.supervisionMinAge || 0
  const isSupervisionAllowed = site?.age_config?.[kit?.kitTypeConfig?.prefix || kitCodePrefix]?.canUseWithSupervision === 'yes'

  if (patientAge >= minAgeLimit) return true

  if (!isSupervisionAllowed) return false

  if (patientAge >= supervisedAgeLimit) return true

  return false
}

export function generateRandomAlphaNumericString (length: number, includeNumber = false) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + (includeNumber ? '0123456789' : '')
  return _.sampleSize(characters, length).join('')
}

export function requireSupervision (patient: PatientI | undefined, site: SiteI | undefined, kit: Kit | undefined) {
  if (!patient || !site || !kit) return false
  // get the cases where we want to show the supervision form. If showSupervision is true then we want to see if the patient
  // age is less than the patientMinAge and greater than the supervisionMinAge
  const kitCodePrefix = getKitCodePrefix(kit.code)
  const patientAge = +getAge(patient.dob)
  const minAgeLimit = site?.age_config?.[kit?.kitTypeConfig?.prefix || kitCodePrefix]?.patientMinAge
  const supervisedAgeLimit = site?.age_config?.[kit?.kitTypeConfig?.prefix || kitCodePrefix]?.supervisionMinAge
  const isSupervisionRequired = kit?.kitTypeConfig?.prefix && site?.age_config?.[kit?.kitTypeConfig?.prefix]?.canUseWithSupervision === 'yes'

  if (!isSupervisionRequired) return false

  return (patientAge < minAgeLimit) && (patientAge >= supervisedAgeLimit)
}

export function checkScreeningQuestions (kitTypeConfig: KitTypeConfigI | undefined, site: SiteI | null = null): boolean {
  if (!kitTypeConfig) return false

  if (site && site.override_screening_questions) {
    const overrideScreeningQuestions = site.override_screening_questions[kitTypeConfig.id]
    if (overrideScreeningQuestions) {
      return overrideScreeningQuestions.length > 0
    }
  }
  return (!!kitTypeConfig.screeningQuestions &&
    kitTypeConfig.screeningQuestions.length > 0)
}

export function removePanelIndex (kitCode = '') {
  if (!kitCode) return kitCode

  const kitCodes = kitCode.split(',')
  const isMultiPanel = kitCodes.length > 1
  if (!isMultiPanel) return kitCode

  const kitCodeWithoutPanelIndex = kitCodes[0].split('-').slice(0, -1).join('-')
  return kitCodeWithoutPanelIndex
}

export function generateShippingURL (vendor: string, track_id: string) {
  if (vendor === 'Fedex') {
    return `https://www.fedex.com/fedextrack/?trknbr=${track_id}`
  } else {
    return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${track_id}%2C&tABt=false`
  }
}
