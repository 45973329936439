import { isShkDomain } from '../../utils/utilFuntions'
import { Paper, Stepper } from 'components'
import { StepLabel, Step } from '@mui/material'
import { useRegisterKit } from '.'
import { useTranslation } from 'react-i18next'
import React from 'react'

function RegisterKitDesktopStepper () {
  const { step } = useRegisterKit()
  const isSHK = isShkDomain()

  const { t } = useTranslation()

  let labels = [
    t('registerKit.kitDetails.title'),
    t('registerKit.patientDetails.title'),
    t('registerKit.screening.title'),
    t('registerKit.paymentMethod.title'),
    t('registerKit.sampleCollection.title'),
    t('registerKit.review.title')
  ]

  if (!step.isPaymentStepRequired) {
    labels = labels.filter(label => label !== t('registerKit.paymentMethod.title'))
  }

  if (!step.isScreeningQuestionsStepRequired) {
    labels = labels.filter(label => label !== t('registerKit.screening.title'))
  }

  const color = isSHK ? 'success.main' : 'primary.main'

  return (
    <Paper
      sx={ {
        width: 260,
        position: 'sticky',
        top: 24,
        display: { xs: 'none', sm: 'flex' }
      } }
    >
      <Stepper
        activeStep={ step.active }
        orientation='vertical'
      >
        { labels.map(label => (
          <Step
            key={ label }
            sx={ {
              '& .MuiStepLabel-root .Mui-completed': {
                color
              },
              '& .MuiStepLabel-root .Mui-active': {
                color
              }
            } }
          >
            <StepLabel>{ label }</StepLabel>
          </Step>
        )) }
      </Stepper>
    </Paper>
  )
}

export default RegisterKitDesktopStepper
