import React from 'react'
import { visibilityStates } from '../utils'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import ListAltIcon from '@mui/icons-material/ListAlt'
import HomeIcon from '@mui/icons-material/Home'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { useTranslation } from 'react-i18next'
import { UserPermissionsI } from '..'
import { filterFromAllRoles, isShkDomain } from 'utils/utilFuntions'

export const useNavLinks = (): NavLinkActionI[] => {
  const { t } = useTranslation()
  const isSHK = isShkDomain()

  const navLinksActions: NavLinkActionI[] = [
    // Portal Links
    {
      title: t('mobileMenu.home'),
      icon: <HomeIcon />,
      navigateTo: '/',
      visibleOn: {
        page: [visibilityStates.page.portalPage],
        role: [],
        features: []
      }
    },
    {
      title: t('header.link.allTests'),
      icon: <ListAltIcon />,
      navigateTo: '/tests',
      visibleOn: {
        page: [visibilityStates.page.portalPage],
        role: [],
        features: []
      }
    },
    {
      title: t('header.link.allPrograms'),
      icon: <ListAltIcon />,
      navigateTo: '/view-programs',
      visibleOn: {
        page: [visibilityStates.page.portalPage],
        role: [],
        features: [],
        isHidden: !isSHK
      }
    },
    {
      title: t('header.link.visits'),
      icon: <ContentPasteIcon />,
      navigateTo: '/visits',
      visibleOn: {
        page: [visibilityStates.page.portalPage],
        role: [],
        features: [visibilityStates.features.consultSection],
        isHidden: false
      }
    },
    {
      title: t('header.link.inbox'),
      icon: <NotificationsIcon />,
      navigateTo: '/inbox',
      visibleOn: {
        page: [visibilityStates.page.portalPage],
        role: [],
        features: []
      }
    },
    {
      title: t('header.link.askForHelp'),
      icon: <></>,
      navigateTo: '#',
      // @ts-ignore
      onClick: () => window.Beacon('toggle'),
      visibleOn: {
        page: [visibilityStates.page.portalPage],
        role: [],
        features: [],
        isHidden: !isSHK
      }
    },
    {
      title: t('header.link.shopMoreTests'),
      icon: <></>,
      navigateTo: '#',
      // @ts-ignore
      onClick: () => window.open('https://www.simplehealthkit.com/where-to-buy/'),
      visibleOn: {
        page: [visibilityStates.page.portalPage],
        role: [],
        features: [],
        isHidden: !isSHK
      }
    },
    // Provider Links
    {
      title: 'Patients',
      icon: null,
      // icon: <Diversity3Icon />,
      navigateTo: '/patients',
      visibleOn: {
        page: [
          visibilityStates.page.providerPage,
          visibilityStates.page.managePage,
          visibilityStates.page.customerAdminPage
        ],
        role: filterFromAllRoles(['portal_care_manager']),
        features: []

      }
    },
    {
      title: 'Tests',
      icon: <ListAltIcon />,
      navigateTo: '/tests',
      visibleOn: {
        page: [
          visibilityStates.page.providerPage,
          visibilityStates.page.managePage,
          visibilityStates.page.customerAdminPage
        ],
        role: filterFromAllRoles(['portal_care_manager']),
        features: []

      }
    },
    {
      title: t('header.link.audit'),
      icon: <PendingActionsIcon />,
      navigateTo: '/audit',
      visibleOn: {
        page: [
          visibilityStates.page.customerAdminPage
        ],
        role: [],
        features: []

      }
    },
    {
      title: 'Message Management',
      icon: <ListAltIcon />,
      navigateTo: '/manage-message',
      visibleOn: {
        page: [
          visibilityStates.page.managePage
        ],
        role: [],
        features: []
      }
    },
    {
      title: 'Outreach Visits',
      icon: <ListAltIcon />,
      navigateTo: '/outreach-visits',
      visibleOn: {
        page: [
          visibilityStates.page.managePage
        ],
        role: [],
        features: []
      }
    },
    {
      title: t('header.link.visits'),
      icon: null,
      navigateTo: '/visits',
      visibleOn: {
        page: [
          visibilityStates.page.providerPage,
          visibilityStates.page.managePage
        ],
        role: ['portal_care_manager', 'portal_admin', 'portal_client_admin', 'portal_client_viewer'],
        features: []

      }
    },
    {
      title: 'Sites',
      icon: null,
      navigateTo: '/sites',
      visibleOn: {
        page: [
          visibilityStates.page.adminPage
        ],
        role: [],
        features: []

      }

    },
    {
      title: 'Product Config',
      icon: null,
      navigateTo: '/product-config',
      visibleOn: {
        page: [
          visibilityStates.page.adminPage
        ],
        role: [],
        features: []

      }

    },
    {
      title: 'Analytics',
      icon: null,
      navigateTo: '/analytics',
      visibleOn: {
        page: [
          visibilityStates.page.adminPage
        ],
        role: [],
        features: []

      }

    },
    {
      title: 'Feedbacks',
      icon: null,
      navigateTo: '/feedbacks',
      visibleOn: {
        page: [
          visibilityStates.page.adminPage
        ],
        role: [],
        features: []

      }

    },
    {
      title: 'Visualization',
      icon: null,
      navigateTo: '/data-viz',
      visibleOn: {
        page: [
          visibilityStates.page.providerPage
        ],
        role: ['portal_admin'],
        features: []

      }

    },
    {
      title: 'Orders',
      icon: null,
      navigateTo: '/provider-orders',
      visibleOn: {
        page: [
          visibilityStates.page.providerPage
        ],
        role: ['portal_admin', 'feat_provider_orders'],
        features: []

      }

    },
    {
      title: 'Program Analytics',
      icon: <></>,
      navigateTo: `https://health.${process.env.REACT_APP_DOMAIN}/analytics/overview`,
      target: '_blank',
      visibleOn: {
        page: [visibilityStates.page.providerPage],
        role: ['portal_admin', 'portal_care_manager'],
        features: []
      }
    },
    {
      title: 'Inbox',
      icon: null,
      navigateTo: '/inbox-dashboard',
      visibleOn: {
        page: [
          visibilityStates.page.providerPage
        ],
        role: ['portal_admin', 'portal_client_admin', 'portal_client_viewer'],
        features: []

      }

    },
    {
      title: 'Order Management',
      icon: null,
      navigateTo: '/orders',
      visibleOn: {
        page: [
          visibilityStates.page.customerAdminPage
        ],
        role: ['operations_manager'],
        features: []

      }

    },
    {
      title: 'Kit Fulfillment',
      icon: null,
      navigateTo: '/labkitfulfill',
      visibleOn: {
        page: [
          visibilityStates.page.customerAdminPage
        ],
        role: ['operations_manager'],
        features: []

      }

    },
    {
      title: 'Actions',
      icon: null,
      navigateTo: '/actions',
      visibleOn: {
        page: [
          visibilityStates.page.managePage
        ],
        role: [],
        features: []

      }

    },
    {
      title: 'Mails',
      icon: <ListAltIcon />,
      navigateTo: '/mails',
      visibleOn: {
        page: [
          visibilityStates.page.managePage
        ],
        role: filterFromAllRoles(['ops_role', 'portal_admin']),
        features: []
      }
    },
    {
      title: 'Faxes',
      icon: <ListAltIcon />,
      navigateTo: '/faxes',
      visibleOn: {
        page: [
          visibilityStates.page.managePage
        ],
        role: filterFromAllRoles(['ops_role', 'portal_admin']),
        features: []
      }
    },
    // Health Portal
    {
      title: 'Members',
      icon: <></>,
      navigateTo: '/members',
      visibleOn: {
        page: [visibilityStates.page.healthPortal],
        role: [],
        features: []

      }
    },
    {
      title: 'Analytics',
      icon: <></>,
      navigateTo: '/analytics/overview',
      visibleOn: {
        page: [visibilityStates.page.healthPortal],
        role: ['portal_admin', 'portal_care_manager'],
        features: []
        // styles: []
      }
    },
    {
      title: 'Analytics',
      icon: <></>,
      navigateTo: '/analytics/insights',
      visibleOn: {
        page: [visibilityStates.page.providerPage],
        role: ['portal_admin', 'portal_provider_analytics'],
        features: []
        // styles: []
      }
    },
    {
      title: 'Translation',
      icon: null,
      navigateTo: '/translation',
      visibleOn: {
        page: [
          visibilityStates.page.adminPage
        ],
        role: [],
        features: []

      }
    }
    // {
    //   title: 'Settings',
    //   icon: null,
    //   navigateTo: '/settings',
    //   visibleOn: {
    //     page: [
    //       visibilityStates.page.adminPage
    //     ],
    //     role: ['portal_admin'],
    //     features: []

    //   }
    // }
    // {
    //   title: 'Go to Account',
    //   icon: <></>,
    //   navigateTo: '/',
    //   onClick: () => { window.open(`//account.${process.env.REACT_APP_DOMAIN}`) },
    //   visibleOn: {
    //     page: Object.values(visibilityStates.page).filter((page) => page !== visibilityStates.page.accountPage)
    //   }
    // }
  ]

  return navLinksActions
}

export interface NavLinkActionI {
  title: string
  icon: JSX.Element | null
  navigateTo: string
  onClick?: () => void
  target?: string
  visibleOn: UserPermissionsI,
}
