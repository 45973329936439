import _ from 'lodash'
import moment from 'moment'
import * as yup from 'yup'
// @ts-ignore
import { PhoneNumberUtil } from 'google-libphonenumber'
import { SAVE_SITE_DETAILS } from 'state/actions'
import patientPortalApi from 'Core/patientPortalApi'
import { cachedQuery } from 'Core/utils/cachingUtils'
import { api } from 'Core'
import { IDMapper, PatientI } from '../types/db/PatientI'
import { Kit } from '../types/db/Kit'
import { AnyAction, Dispatch } from 'redux'
import { ChangeEvent } from 'react'
import { SiteI } from '../types/db/SiteI'
import useFormik from '../hooks/useFormik'
import { SampleI } from '../types/db/SampleI'
import { i18n } from 'i18next'
import { esES } from '@mui/x-date-pickers/locales'
import html2canvas from 'html2canvas'
const phoneUtil = PhoneNumberUtil.getInstance()

export const allowedHosts = ['shkdev.link', 'simplehealthkit.com', 'shkdev.org', 'sales.shkdemo.me', 'local.com']

export const isShkDomain = () => allowedHosts.includes(getDomainName())

export const toTitleCase = (str = '') => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function getSiteIds (patients: PatientI[] = []) {
  const siteIds: string[] = []

  patients.forEach(p => {
    if (p.idMapper.length > 1) {
      p.idMapper.forEach(idm => {
        siteIds.push(idm.site_id)
      })
    } else {
      siteIds.push(p.idMapper[0].site_id)
    }
  })

  return _.uniq(siteIds)
}

export function filterPatientsBySite (patients: PatientI[] = [], siteId: string) {
  let filteredPatients = patients.filter(patient => {
    if (patient.idMapper.length > 1) {
      for (let i = 0; i < patient.idMapper.length; i++) {
        if (+patient.idMapper[i].site_id === +siteId) {
          return true
        }
      }
    } else {
      if (+patient.idMapper[0].site_id === +siteId) {
        return true
      }
      return false
    }
    return false
  })

  filteredPatients = filteredPatients.map(patient => ({ ...patient, id: patient?.idMapper.find(idMap => +idMap.site_id === +siteId)!.patient_id }))

  return filteredPatients
}

export function formatDate (date: Date | string) {
  return moment(date, ['YYYY-MM-DD', 'MM-DD-YYYY']).format('MM-DD-YYYY')
}

export function formatDateWithSlash (date: Date | string) {
  return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
}

export function limsFormatDate (date: Date | string) {
  return moment(date, ['MM-DD-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')
}

export function validatePhoneNumber (phone: string) {
  try {
    if (!phone) return false
    const number = phoneUtil.parseAndKeepRawInput(phone, 'US')
    return phoneUtil.isValidNumberForRegion(number, 'US')
  } catch (error) {
    return false
  }
}

export function getKitStatus (kit: Kit) {
  if (kit.status === 'New') return 'Registered'
  if (kit.status === 'Active') return 'Processing'
  if (kit.status === 'Resulted') return 'Processing'
  if (kit.status === 'Approved') return 'Ready'
  if (kit.status === 'Rejected') return 'Rejected'
  if (!kit.lims_status) return 'Not Registered'
  return kit.status
}

export function jsonToCSV (jsonData: any[], fields: string[] = []): string {
  // Find the object with the most keys
  const fieldsSet = new Set<string>()
  jsonData.forEach(item => {
    Object.keys(item).forEach(key => {
      fieldsSet.add(key)
    })
  })

  const csvHeaders = [...fieldsSet].join(',')

  const csvRows = jsonData.map(item => {
    const csvValues = [...fieldsSet].map(field => {
      const value = item[field]
      if (value !== null && typeof value === 'string') {
        // Check if value contains comma or is a JSON string
        if (value.includes(',') || (value.startsWith('{') && value.endsWith('}'))) {
          return `"${value.replace(/"/g, '""')}"`
        }
      }
      return value === null ? 'null' : value
    })

    return csvValues.join(',')
  })

  return [fields.length === 0 ? csvHeaders : fields.join(','), ...csvRows].join('\n')
}

export const dobValidationSchema = yup
  .string()
  .min(10, 'Invalid Date')
  .required('Date Of Birth is Required')
  .test(
    'DOB',
    'The Date Of Birth must be valid and in the format DD/MM/YYYY, should not be in the future and should not be before 1900',
    (value: string | undefined): boolean => {
      if (!value) return false

      const isDateValid = moment(value).isValid()
      if (!isDateValid) return false

      const maxAge = moment().diff(moment('1900-01-01'), 'years')
      return (
        moment().diff(moment(value), 'years') >= 0 &&
        moment().diff(moment(value), 'years') < maxAge
      )
    }
  )

export const appendPstTz = (dateWithoutTz = '') => {
  const date = dateWithoutTz.split(' ')[0]
  const time = dateWithoutTz.split(' ')[1]
  return `${date}T${time}-07:00`
}

export function appendQueryString (url: string, queryStringObject: Record<string, string> = {}) {
  if (!queryStringObject) return url

  const filteredQueryStrings = _.pickBy(queryStringObject, _.identity)

  const queryString = new URLSearchParams(filteredQueryStrings).toString()
  if (queryString) {
    url = url.concat('?').concat(queryString)
  }
  return url
}

export function formatDateTime (dateTime: string | Date) {
  return moment(dateTime).format('lll')
}

export function getSubdomain () {
  return window.location.hostname.split('.')[0]
}

export const getSiteContent = async ({ sitecode, dispatch }: { sitecode: string, dispatch: Dispatch<AnyAction> }) => {
  const res = await cachedQuery(sitecode, () =>
    api.admin.validateSite(sitecode)
  )

  if (!res.data) {
    window.sessionStorage.removeItem(sitecode)
    window.sessionStorage.removeItem(
      `${sitecode}ExpiryToken`
    )
    throw Error('Invalid Client Site Code')
  } else {
    try {
      const insuranceNames = await cachedQuery('insurance', () =>
        api.proxy.getInsuranceProviders()
      )
      const insuranceData = insuranceNames.data.map((data: { name: string, id: string }) => ({
        label: data.name,
        id: data.id
      }))

      const race = await cachedQuery(
        `${sitecode}-race`,
        () => patientPortalApi.getRace(sitecode)
      )
      const gender = await cachedQuery(
        `${sitecode}-gender`,
        () => patientPortalApi.getGender(sitecode)
      )
      const ethnicity = await cachedQuery(
        `${sitecode}-ethnicity`,
        () => patientPortalApi.getEthnicity(sitecode)
      )
      dispatch({
        type: SAVE_SITE_DETAILS,
        payload: {
          ...res.data,
          ethnicity: ethnicity.data,
          race: race.data,
          gender: gender.data,
          insuranceNames: insuranceData
        }
      })
    } catch (error) {
      console.log(error)
      throw Error('There was some error getting the Site Code data')
    }
  }
}

export const toUpperOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
  evt.target.value = evt.target.value.toUpperCase()
}

export const getPatientIdFromMapper: GetPatientIdFromMapperI = (mapper, site) => {
  if (!site) return undefined

  const patientDetails = mapper.find((details) => +details.site_id === +site.id)
  return patientDetails && patientDetails.patient_id
}

export const checkPatientExistsInSite = (patient: PatientI, siteCode: SiteI | undefined) => {
  if (!siteCode) return false

  const siteDetails = patient.idMapper
  const siteFoundInPatient = siteDetails.findIndex((details) => +details.site_id === +siteCode.id)

  return siteFoundInPatient !== -1
}

export function getAge (dob: string | Date) {
  return +moment().diff(moment(dob, ['MM-DD-YYYY', 'YYYY-MM-DD']), 'years', false)
}

export function getBase64 (file: File) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export function dataURLtoFile (dataurl: string, filename: string) {
  const arr = dataurl.split(',')
  const mime = (arr.length > 0) ? arr[0].match(/:(.*?);/)![1] : ''
  const base64 = arr[1]
  const byteCharacters = Buffer.from(base64, 'base64')
  const byteArray = new Uint8Array(byteCharacters)

  return new File([byteArray], filename, { type: mime })
}

export function fileToDataUrl (file: { mimetype: string, buffer: string }) {
  return `data:${file.mimetype};base64,${Buffer.from(file.buffer).toString('base64')}`
}

export const positiveResults = ['Detected', 'Reactive', 'Positive', 'Presumptive Positive', 'Abnormal', 'Prediabetes', 'Diabetes', 'High', 'High Risk', 'Preliminary Reactive', 'Severely Increased']

export function isPositiveResult (result: SampleI) {
  const isDetected = positiveResults.includes(result.result)
  return isDetected
}

export function convertToUSFormat (phoneNumber: string) {
  const cleaned = phoneNumber.replace(/\D/g, '')

  if (cleaned.length !== 10) {
    return phoneNumber
  }

  const formatted =
    '(' +
    cleaned.substring(0, 3) +
    ') ' +
    cleaned.substring(3, 6) +
    '-' +
    cleaned.substring(6)

  return formatted
}

export function jsonValidationTest (value: string) {
  try {
    JSON.parse(value)
    return true
  } catch (error) {
    return false
  }
}

export function getJSONFromString (value: any, defaultValue = {}) {
  try {
    return JSON.parse(value)
  } catch (error) {
    return defaultValue
  }
}

export function getRandomColor () {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const getTextFieldProps = ({ formik, name, ...overrides }: { formik: ReturnType<typeof useFormik>, name: string }) => ({
  name,
  error: formik.isError(name),
  helperText: formik.getError(name),
  value: formik.values[name],
  onChange: formik.handleChange,
  ...overrides
})

export function isPasswordContainsUserInfo (formData: { password: string, firstName: string, lastName: string, email: string }) {
  if (!formData.password.length) {
    return false
  }
  const passwordContainsUserInfo = _.some([formData.firstName, formData.lastName, formData.email.split('@')[0]], info => {
    if (!info.length) return false
    return _.includes(formData.password.toLowerCase(), info.toLowerCase())
  })
  return passwordContainsUserInfo
}

export const svgToPng = (svg: SVGElement, width: number, height: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    const canvas: HTMLCanvasElement = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')

    if (!ctx) {
      reject(new Error('Canvas 2D context unavailable'))
      return
    }

    // Set background to white
    ctx.fillStyle = '#ffffff'
    ctx.fillRect(0, 0, width, height)

    const xml: string = new XMLSerializer().serializeToString(svg)
    const dataUrl: string = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml)
    const img: HTMLImageElement = new Image(width, height)

    img.onload = () => {
      ctx.drawImage(img, 0, 0)
      const imageData: string = canvas.toDataURL('image/png', 1.0)
      resolve(imageData)
    }

    img.onerror = () => reject(new Error('Image loading failed'))

    img.src = dataUrl
  })
}

export const elementToPng = (element: HTMLElement, width: number, height: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    html2canvas(element, {
      width: width,
      height: height,
      scrollX: 0,
      scrollY: 0,
      useCORS: true,
      backgroundColor: '#ffffff'
    }).then((canvas) => {
      const imageData: string = canvas.toDataURL('image/png', 1.0)
      resolve(imageData)
    }).catch((error) => {
      reject(new Error('html2canvas failed: ' + error.message))
    })
  })
}

export const getDomainName = () => {
  const hostnameParts = window.location.hostname.split('.')
  if (hostnameParts.length === 4) {
    return hostnameParts.slice(-3).join('.')
  }
  return hostnameParts.slice(-2).join('.')
}

export function formatNumberWithCommas (inputNumber: string | number) {
  // Convert the input number to a string
  const numberString = typeof inputNumber === 'number' ? inputNumber.toString() : inputNumber

  // Use regex to add commas every three digits from the right
  const result = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return result
}

// interfaces and types
type GetPatientIdFromMapperI = (mapper: IDMapper[], site: SiteI | undefined) => number | undefined

export function toFormData (obj: Record<string, any>) {
  const formData = new FormData()

  _.forEach(obj, (value, key) => {
    formData.append(key, value)
  })

  return formData
}

export function getLocaleText (i18n: i18n) {
  const localeTexts = {
    'es-US': esES.components.MuiLocalizationProvider.defaultProps.localeText
  }
  return localeTexts[i18n.language as keyof typeof localeTexts]
}

export function getTimeOptions () {
  const currentDate = new Date()
  const lastThreeYears: any[] = []
  const months = []

  for (let i = 0; i < 36; i++) {
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2)
    const year = currentDate.getFullYear()
    const formattedDate = `${year}/${month}`

    if (!lastThreeYears.includes(year)) {
      lastThreeYears.push(year)
    }

    months.push(formattedDate)
    currentDate.setMonth(currentDate.getMonth() - 1)
  }

  return months
}

export function filterFromAllRoles (roles: string[]): string[] {
  const all_roles = [
    'client_provider_admin',
    'client_provider_viewer',
    'external',
    'labportalv2_role',
    'labtech',
    'operations_manager',
    'ops_role',
    'portal_admin',
    'portal_billing',
    'portal_care_manager',
    'portal_client_admin',
    'portal_client_viewer',
    'portal_demo_features',
    'portal_manage',
    'portal_payer_admin',
    'portal_pharmacist',
    'portal_provider_analytics',
    'portal_site_admin'
  ]
  return all_roles.filter(role => !roles.includes(role))
}

export function formatPhoneNumber (number: string) {
  if (
    typeof number !== 'string' ||
    number.length !== 10 ||
    !/^\d+$/.test(number)
  ) {
    return 'Invalid input'
  }

  const areaCode = number.slice(0, 3)
  const firstPart = number.slice(3, 6)
  const secondPart = number.slice(6)

  return `(${areaCode}) ${firstPart}-${secondPart}`
}

export function appointmentToSlot (appointment: any) {
  if (!appointment) return ''
  const date = appointment.dateIso || appointment.date

  return `${moment(date).format('hh:mm A')} - ${moment(date).add(appointment.length, 'minutes').format('hh:mm A')}`
}

export function isValidJson (json: any) {
  try {
    JSON.parse(json)
    return true
  } catch (error) {
    return false
  }
}

export const checkIfDateHasPassed = (date: string) => {
  return moment().startOf('day').utc().isAfter(moment(date).utc())
}

export const showCareCoaching = async (appointment: any) => {
  if (!appointment || !appointment.data || appointment.data.hasOptOut || appointment.data.isFinished || !appointment.ol_appointment_id || checkIfDateHasPassed(appointment.date)) return false

  const { hasAppointmentOccured } = await api.proxy.hasAppointmentOccured({ mwl_id: appointment.mwl_consult_id, ol_appointment_id: appointment.ol_appointment_id })
  return !(hasAppointmentOccured)
}

export const showVirtualVisit = async (appointment: any) => {
  if (appointment?.status === 'cancelled') return true
  if (!appointment || !appointment.data || (appointment.type !== 'virtual_visit_sync' && !appointment.ol_appointment_id && appointment.data.isFinished && checkIfDateHasPassed(appointment.date))) return false

  const { hasAppointmentOccured } = await api.proxy.hasAppointmentOccured({ mwl_id: appointment.mwl_consult_id, ol_appointment_id: appointment.ol_appointment_id })
  return !(hasAppointmentOccured)
}

export const showVirtualVisitOnDashboard = async (appointment: any) => {
  if (appointment?.status === 'cancelled') return true
  if (!appointment || appointment.isFinished || checkIfDateHasPassed(appointment.date)) return false

  const { hasAppointmentOccured } = await api.proxy.hasAppointmentOccured({ mwl_id: appointment.mwl_consult_id, ol_appointment_id: appointment.ol_appointment_id })
  return !(hasAppointmentOccured)
}

export const nextBillingCycle = (start: number, end: number, current_ts: null | number = null) => {
  if (!start || !end) throw new Error('Not sufficient subscription info')
  const cycle_duration = end - start // calcualte duration of each cycle

  current_ts = current_ts || Math.floor(Date.now() / 1000) // current timestamp in unix
  const next_cycle = Math.ceil((current_ts - start) / cycle_duration) // next cycle
  const next_cycle_ts = start + next_cycle * cycle_duration // next cycle timestamp in unix

  return next_cycle_ts
}

export const isDateInCurrentMonth = (date: any) => {
  if (!date) return false
  const now = new Date()
  return now.getMonth() === new Date(date).getMonth() && now.getFullYear() === new Date(date).getFullYear()
}

export function isValidHttpUrl(url : string) {
  let isUrl;
  
  try {
    isUrl = new URL(url);
  } catch (_) {
    return false;  
  }

  return isUrl.protocol === "http:" || isUrl.protocol === "https:";
}

export function getOrdinal (n: number) {
  const suffix = ['th', 'st', 'nd', 'rd']
  const value = n % 100
  return n + (suffix[(value - 20) % 10] || suffix[value] || suffix[0])
}
